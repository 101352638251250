<template>
  <v-dialog v-model="parent.uploadInvoiceShowModal" max-width="600px" persistent>
    <v-card>
      <v-card-title class="text-h6 white--text px-2 py-1" :class="titleClass">
        {{ labels.BTN_INVOICE_DETAILS_SHINKI }}
      </v-card-title>
      <v-file-input
        class="px-4 pt-4 py-0"
        ref="invoiceCsvFileRef"
        v-model="invoiceCsvFile"
        label="Invoice"
        :rules="weightInputFileRules"
        accept="text/csv"
        outlined
        clearable
        counter
        show-size
      >
      </v-file-input>
      <v-file-input
        class="px-4 pt-4 py-0"
        ref="detailCsvFileRef"
        v-model="detailCsvFile"
        label="Details"
        :rules="weightInputFileRules"
        accept="text/csv"
        outlined
        clearable
        counter
        show-size
      >
      </v-file-input>
      <v-card-actions class="d-flex justify-end">
        <v-btn dense color="primary darken-1" :loading="uploadLoading" :disabled="disabled" @click="register">
          OK
        </v-btn>
        <v-btn
          dense
          color="orange darken-1"
          text
          :disabled="uploadLoading"
          @click="parent.uploadInvoiceShowModal = false"
        >
          cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  props: {
    parent: {
      type: Object,
      default: Object,
      required: true,
    },
  },
  emits: ['registered'],
  data() {
    return {
      invoiceCsvFile: null,
      detailCsvFile: null,
      weightInputCsvFile: null,
      weightInputFileRules: [(value) => !value || value.size < 10000000 || 'CSV file should be less than 10 MB!'],
    };
  },
  methods: {
    ...mapActions({
      apiGetInvoiceLists: 'api/getInvoiceLists',
      apiGetInvoiceDetails: 'api/getInvoiceDetails',
      apiGetPackingDetails: 'api/getPackingDetails',
      apiAutoConsolidateBoxes: 'api/autoConsolidateBoxes',
      registerInvoiceCsv: 'api/registerInvoiceCsv',
      readAndDeflate: 'common/readAndDeflate',
      setCustomErrorMessage: 'ui/setCustomErrorMessage',
      setUploadLoadingStatus: 'ui/setUploadLoadingStatus',
    }),
    async register() {
      this.setUploadLoadingStatus(true);
      const result = await this.registerInvoiceCsv({
        invoice: await this.readAndDeflate(this.invoiceCsvFile),
        detail: this.detailCsvFile ? await this.readAndDeflate(this.detailCsvFile) : null,
      });
      this.setUploadLoadingStatus(false);
      this.parent.uploadInvoiceShowModal = false;
      if (result.length > 0 && result[0].invoice_no && result[0].invoice_id) {
        await Promise.all([this.apiGetInvoiceLists(true), this.apiGetInvoiceDetails(result[0].invoice_id)]);
        const consolidated = await this.apiAutoConsolidateBoxes({
          invoiceId: result[0].invoice_id,
          dataSource: 2,
        });
        this.parent.hakomatomezumi = true;
        this.parent.selectedInvoice = result[0].invoice_no;
        consolidated.map(({ error }) => error).length > 0
          ? await this.apiGetPackingDetails(result[0].invoice_no)
          : void 0;
        this.$emit('registered', result[0].invoice_no, consolidated, consolidated.map(({ error }) => error).length > 0);
        this.parent.uploadInvoiceShowModal = false;
      } else if (result[0].errors) {
        this.setUploadLoadingStatus(false);
        this.setCustomErrorMessage(
          result[0].errors
            .map((error) => {
              return error;
            })
            .join('\n '),
        );
      }
    },
  },
  computed: {
    ...mapGetters({
      labels: 'defined/labels',
      error: 'ui/error',
      success: 'ui/success',
      uploadLoading: 'ui/uploadLoading',
    }),
    titleClass() {
      return {
        red: this.error,
        primary: !this.error && !this.success,
        success: this.success,
      };
    },
    disabled() {
      if (!this.invoiceCsvFile) {
        return true;
      } else return false;
    },
  },
  watch: {
    'parent.uploadInvoiceShowModal'() {
      if (!this.parent.clickedWeightInput) {
        this.invoiceCsvFile = null;
        this.detailCsvFile = null;
      }
    },
  },
};
</script>
<style scoped>
div.v-card__text {
  white-space: pre-wrap;
}
</style>
